$('.seating-chart-wrapper .reset').on('click', (e) => {
  setTimeout(()=>{
    $(e.target).prop('disabled', true)
  }, 200)
})

$('.seating-chart-wrapper .filter-container .buttons-wrapper .switch').on('click', () => {
  if ($('.seating-chart-wrapper .reset').prop('disabled') == true) {
    $('.seating-chart-wrapper .reset').prop('disabled', false)
  }  
})

document.addEventListener('turbo:load', () => {
  let urlFilters = new URLSearchParams(window.location.search).get('package-group')
  !urlFilters && $('.seating-chart-wrapper .reset').prop('disabled', true)
})
