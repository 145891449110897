$(document)
  .on('change', '.container-fluid input[type=checkbox]', () => {
    $('.group-package').each(function () {
      if ($(this).hasClass('pkg-hidden')) {
        $(this).parent().addClass('pkg-hidden')
      } else {
        $(this).parent().removeClass('pkg-hidden')
      }
    })
  })

$(document)
  .on('change', '.price-slider .slider', () => {
    $('.group-package').each(function () {
      if ($(this).hasClass('pkg-hidden')) {
        $(this).parent().addClass('pkg-hidden')
      } else {
        $(this).parent().removeClass('pkg-hidden')
      }
    })
  })

$('.reset').on('click', () => {
  $('.seatingChartPackage').each(function () {
    $(this).removeClass('pkg-hidden')
  })
})
