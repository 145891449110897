//mobile-packages-filter
$(document)
  .on('click', '.mobile-packages-filter-header .filter-heading .btn-inverse', function () {
    document.querySelector('.packages-filter .mobile-packages-filter').classList.add('show')
    document.querySelector('body.renderable_seating_chart').classList.add('navbar-collapsed')
  })

  .on('click', '.mobile-packages-filter .icon-cross', function () {
    document.querySelector('.packages-filter .mobile-packages-filter').classList.remove('show')
  })


$(document).on('turbo:load', function () {
  $('.header-icon-wrapper .header-icon').on('click', function (e) {
    e.preventDefault()
    let option = e.currentTarget

    if(!option.classList.contains('disabled')) {
      if ($('.seating-chart-wrapper .reset').prop('disabled') == true) {
        $('.seating-chart-wrapper .reset').prop('disabled', false)
      }
    }
  })
})
